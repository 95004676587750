import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { Observable } from 'rxjs';
import { AuthService, UserType } from '../../services/auth.service';
import { UserModel } from '../../services/user-model';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { Menu } from '../../interfaces/interfaces';
import { getEnvironment } from '../../environment/environment';

declare var $: any;

@Component({
  selector: 'main-layout',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    SidebarComponent,
    HeaderComponent,
    FooterComponent,
  ],
})
export class MainLayoutComponent implements OnInit {

  @ViewChild('cttMain') cttMain?: ElementRef;

  user$: Observable<UserType>;
  title_page: any = '';

  menu: Menu[] = [];

  env = getEnvironment();

  nameTitleMenuAbonos:string = 'Abono';
  nameTitleMenuUsuarioInscripto:string = 'Registrar usuario/inscrito';

  constructor(
    private authService: AuthService,
    public userModel: UserModel
  ) {
    switch (this.env.type) {
      case 'race-ligalapaz':
        this.nameTitleMenuAbonos = 'Inscripciones'
        break;
        case 'oriente-petrolero':
          this.nameTitleMenuAbonos = 'Socios'
          this.nameTitleMenuUsuarioInscripto = 'Registrar usuario/Socio';
          break;
          case 'spazio':
            this.nameTitleMenuAbonos = 'Inscrito'
        break;
      default:
         this.nameTitleMenuAbonos = 'Abono';
         this.nameTitleMenuUsuarioInscripto = 'Registrar usuario/inscrito';
        break;
    }

    this.user$ = this.authService.user$;
    this.user$.subscribe((data) => {
      this.menu = [];
      if (data) {
        console.log(data, "estos para los permisos");
        (data.permissions ?? []).forEach((permission: string) => {
          switch (permission) {
            case 'page-users':
              this.menu.push({ name: 'Usuarios', url: '/users', external: false, icon: 'fa-light fa-users', iconType: 'icon' })
              break;
            case 'page-users-register':
              this.menu.push({ name: 'Registrar usuario', url: '/users-register', external: false, icon: 'fa-light fa-users-medical', iconType: 'icon' },
              )

              break;
            case 'page-subscription-abonos':
              this.menu.push({ name: this.nameTitleMenuAbonos, url: '/subscription-abonos/1', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' },
              )

              break;
            case 'page-subscription-socios':
              this.menu.push({ name: 'Socios', url: '/subscription-socios/2', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' })


              break;
            case 'page-commission-abonos':
              this.menu.push({ name: 'Comisiones Abonos', url: '/commission-abonos/1', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' })
              break;
            case 'page-commission-socios':
              this.menu.push({ name: 'Comisiones Sociedades', url: '/commission-socios/2', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' },
              )

              break;

            case 'page-seat-map':
              this.menu.push({ name: 'Mapa de asientos', url: '/seat-map', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' },
              )

              break;
            case 'page-minute-x-minute':
              this.menu.push({ name: 'Minuto a minuto', url: '/minute-x-minute', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' },
              )

              break;
            case 'page-push-notification':
              this.menu.push({ name: 'Notificaciones push', url: '/push-notification', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' },
              )

              break;

            case 'page-bulk-load':
              this.menu.push({ name: 'Carga masiva', url: '/bulk-load', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' },
              )
              break;
            case 'page-assistances':
              this.menu.push({ name: 'Asistencia', url: '/assistances', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' })

              break;
            case 'page-emails':
              this.menu.push({ name: 'Correos', url: '/emails', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' })

              break;
            case 'page-send-email':
              this.menu.push({ name: 'Enviar correo masivo', url: '/send-email', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' })

              break;

            case 'page-user-suscription':
              this.menu.push({ name: this.nameTitleMenuUsuarioInscripto, url: '/user-suscription', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' })

              break;
            case 'page-corporative-bulk-load':
              this.menu.push({ name: 'Corporativo: Carga masiva', url: '/corporative-bulk-load', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' })

              break;
            case 'page-corporative-subscriptions-list':
              this.menu.push({ name: 'Corporativo: Abonos/Membresias', url: '/corporative-subscriptions-list', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' })
              break;

            case 'page-regenerate-transaction':
              this.menu.push({ name: 'Generar transacciones', url: '/regenerate-transaction', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' })
              break;

            case 'page-income-report':
              this.menu.push({ name: 'Reporte de Asistencia', url: '/income-report', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' });
              break;
            case 'page-detailt-attandace':
              this.menu.push({ name: 'Detalle de asistencia', url: '/detailt-attandace', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' })
              break;
            case 'page-customer-subscriptions':
              this.menu.push({ name: 'Membresias', url: '/customer-subscriptions', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' })
              break;
            case 'page-contribution-club':
              this.menu.push({ name: 'Aportes voluntarios', url: '/contribution-club', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' })
              break;
            case 'page-vouchers':
              this.menu.push({ name: 'Vales / Cupones', url: '/vouchers', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' })
              break;
            case 'page-cashier':
              this.menu.push({ name: 'Caja', url: '/cashier', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' })
              break;
            case 'page-courtesy':
              this.menu.push({ name: 'Abonos/cortesías', url: '/courtesy', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' })
              break;
            case 'page-reactivate-user':
              this.menu.push({ name: 'Reactivar usuario', url: '/reactivate-user', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' })
              break;
            case 'page-report-list':
              this.menu.push({ name: 'Reportes', url: '/report-list', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' })
              break;

            default:
              break;
          }
        })
       // this.menu.push({ name: 'Registrar usuario/abono', url: '/user-suscription', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' })

      }
    })

    // this.user$.subscribe(data => {
    //   if (data) {
    //     this.menu = [
    //       { name: 'Registrar usuario', url: '/register', external: false, icon: 'fa-light fa-users-medical', iconType: 'icon' },
    //       { name: 'Abono', url: '/suscriptions', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' },
    //       { name: 'Socio', url: '/memberships', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' },
    //       { name: 'Comisiones Abonos', url: '/comission_suscription/1', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' },
    //       { name: 'Comisiones Sociedades', url: '/comission_society/2', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' },
    //       { name: 'Mapa de asientos', url: '/seats', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' },
    //       { name: 'Generador de notificaciones', url: '/push-notifications', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' },
    //       // { name: 'Reportes', url: '/report', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' },
    //       // { name: 'Tienda', external: false, submenu: [
    //       // 	{ iconType: 'img', icon: 'assets/img/header/shop.png', url: '/shop', external: false, name: 'Tienda' },
    //       // 	{ iconType: 'img', icon: 'assets/img/header/card.png', url: '/acquire/2', external: false, name: 'Socios' },
    //       // 	{ iconType: 'img', icon: 'assets/img/header/card.png', url: '/acquire/1', external: false, name: 'Abonos' },
    //       // ]},
    //       // { name: 'Mis pagos',external: false, submenu: [
    //       // 	{ iconType: 'img', icon: 'assets/img/header/card.png', url: '/pending-payments', external: false, name: 'Pagos pendientes' },
    //       // 	{ iconType: 'img', icon: 'assets/img/header/book.png', url: '/payments', external: false, name: 'Historial y facturas' },
    //       // ]},
    //       // { name: 'Beneficios', url: '/benefits', external: false },

    //       // { name: 'Solicitud Abono Adicional', url: '/request-subscription/abono-adicional', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' },
    //       // { name: 'Solicitud Abono Estudiante', url: '/request-subscription/abono-estudiante', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' },
    //     ];
    //     if (data.role_id == 1 || data.role_id == 2 || data.role_id == 7) {
    //       this.menu.unshift(
    //         { name: 'Usuarios', url: '/users', external: false, icon: 'fa-light fa-users', iconType: 'icon' }
    //       );
    //     }
    //     if (data.role_id == 7) {
    //       [
    //         { name: 'Registrar usuario', url: '/register', external: false, icon: 'fa-light fa-users-medical', iconType: 'icon' },
    //         { name: 'Abono', url: '/suscriptions', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' },
    //         { name: 'Socio', url: '/memberships', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' },
    //         { name: 'Mapa de asientos', url: '/seats', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' },
    //         // { name: 'Solicitud Abono Estudiante', url: '/request-subscription/abono-estudiante', external: false, icon: 'fa-light fa-rocket', iconType: 'icon' },
    //       ];
    //     }
    //   }
    // });
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    $(function () {
      $('.toogle_dropdown').on('click', function (this: any) {
        $(this).next('.dropdown').slideToggle();
      });

      $('.toogle_dropdown_a').on('click', function (this: any) {
        $('.dropdown').slideUp()
      });

      $(document).on('click', function (e: any) {
        var target = e.target;
        if (!$(target).is('.toogle_dropdown') && !$(target).parents().is('.toogle_dropdown')) {
          $('.dropdown').slideUp()
        }
      });

      $('.content_header-space').css('padding-top', `${$('.header__menu').outerHeight()}px`);

      $('.btn_a-sidebar').on('click', function () {
        $('.bg__sidebar_left').fadeOut();
        $('.sidebar_left').removeClass('active');
      });
    });

    $('.btn__sidebar, .bg__sidebar_left').on('click', function () {
      $('.bg__sidebar_left').fadeToggle();
      $('.sidebar_left').toggleClass('active');
    });
  }

}
